import React from "react"
// import { useStaticQuery, graphql } from "gatsby"

const Button = ({size, label, use}) => {
    switch (use) {
        case "form":
            return(
                <button className="btn-form-action">{label}</button>
            )
        case "news-events":
            return(
                <button className="btn-news-events">{label}</button>
            )

        case "addToCart":
            return(
                <button className="btn-add-to-cart">{label}</button>
            )

        default:
            return(
                <button className="btn-default-action">{label}</button>
            )
    }
    
}

export default Button