import { useStaticQuery, graphql, Link } from "gatsby"
import React from "react"

import Logo from "../assets/SVG/MindfulMemorials_LogoSlate.svg"
// import BasketWidget from "./basket-widget"
// import SearchWidget from "./search-widget"

import DefaultMaterialImage from "../assets/img/grey.png"

import Img from "gatsby-image"
// import { GatsbyImage } from "gatsby-plugin-image"

// const DefaultMaterialImage = "http://placehold.it/24x24.jpg"
import { isSlugExisting } from '../utils/customFunctions'


import loadable from '@loadable/component'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"



const BasketWidget = loadable(() => import("./basket-widget"))
const SearchWidget = loadable(() => import("./search-widget"))
const EnquiryPopup = loadable(() => import("./enquiry-popup"))


const Nav = () => {

    const url = typeof window !== 'undefined' ? window.location.href : '';

    const data = useStaticQuery(graphql`
    {
    strapiMainMenu {
        Menu {
            URL
            Label
            id
        }
        Discovermore {
            Title
            Url
            Image {
                localFile{
                    publicURL
                    childImageSharp{
                        fluid(quality: 90, maxHeight: 170) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                }
                
            }
        }
        KeyInformation {
            Column1 {
              Link
              URL
            }
            Column2 {
              Link
              URL
            }
            DiscoverMore {
              Image {
                  localFile{
                    publicURL
                    childImageSharp{
                        fluid(quality: 90, maxHeight: 170) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                  }
                
              }
              Title
              Url
            }
        }
        MobileMenu {
            Link
            URL
        }
    }

    allJrmProducts {
        nodes {
        PayOnline
        HidePrices
          Id
          Name
          AttractivePrice
          Description
          Slug
        }
    }

    allJrmCategories {
        nodes {
            Name
            Id
        }
    }

    allJrmFilteredCategories {
        nodes {
            Id
        }
    }

    allJrmMaterialTypes {
      nodes {
        Name
        Id
        DisplayOnFrontend
        Image
      }
    }

  }
`)


    var FilteredCategories = []
    data.allJrmCategories.nodes.forEach((item, index) => {
        var exists = data.allJrmFilteredCategories.nodes.find(filteredItem => filteredItem.Id === item.Id)
        if (exists) {
            FilteredCategories.push({
                Id: item.Id,
                Name: item.Name
            })
        }
    })






    return (
        <div>
            <div className={" navigationBar"}>
                <div data-uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent; top:100">
                    <nav className="nav-options uk-navbar-container uk-position-top uk-navbar-transparent lateral-padding" data-uk-navbar>
                        <div className="uk-navbar-left" >
                            <ul className="uk-navbar-nav">
                                <li>
                                    <Link className="logoLight" to="/">
                                        {/* <StaticImage src="../assets/SVG/MindfulMemorials_LogoSlate.svg" alt='The National Association of Memorial Masons logo' width={160} height={50}/> */}
                                        <img src={Logo} alt="Memorials Logo" width='160' height='50' />
                                    </Link>
                                    {/* <Link className="logoDark" to="/"><img src={LogoDark} alt="The insurers logo dark" width="200"/></Link> */}


                                </li>
                            </ul>
                        </div>

                        

                        <EnquiryPopup></EnquiryPopup>

                        <div className="uk-navbar-right uk-visible@m">
                            {/* <SearchWidget data={data.allJrmProducts.nodes}></SearchWidget>
                            <BasketWidget></BasketWidget> */}

                            <ul className="uk-navbar-nav uk-navbar-nav-padding">

                            <li><Link to={"/collections"}>Products <span className="link-dropdown-arrow" uk-icon="icon: chevron-down; ratio: 1"></span></Link></li>

                            <div id="drop-down-menu" className="uk-navbar-dropdown" data-uk-drop="offset: 0; boundary: !nav; boundary-align: true; pos: bottom-justify; delay-hide:100;">
                                <div className="uk-navbar-dropdown-grid uk-child-width-1-2" data-uk-grid>
                                    <div className="uk-navbar-dropdown-grid uk-child-width-1-2" data-uk-grid>
                                        <ul className="uk-nav uk-navbar-dropdown-nav">
                                            <div className="dropdown-column-title">BY MATERIAL</div>
                                            {data.allJrmMaterialTypes.nodes.map((item, index) => {

                                                if (item.DisplayOnFrontend === true) {

                                                    var matImg = (item.Image !== null) ? process.env.GATSBY_IMAGE_PATH_URL + '/' + item.Image : DefaultMaterialImage

                                                    return (
                                                        <li key={index}>
                                                            {/* <StaticImage src={matImg} alt={'jdhd'} /> */}

                                                            <Link to={"/material/" + item.Name.replace(/\s+/g, '-').toLowerCase()}>


                                                                <img src={matImg} alt={item.Name}></img>

                                                                {item.Name}</Link></li>
                                                    )

                                                }
                                                else return (<></>)

                                            })}

                                        </ul>
                                        <ul className="uk-nav uk-navbar-dropdown-nav">
                                            <div className="dropdown-column-title">BY TYPE</div>
                                            {FilteredCategories.map((item, index) => {
                                                return (<li key={index}><Link to={"/product-list/" + item.Name.replace(/\s+/g, '-').toLowerCase()} className="nav-category-link">{item.Name.toLowerCase()}</Link></li>)
                                            })}
                                        </ul>
                                    </div>
                                    <div>
                                        {/* <div className="dropdown-column-title">DISCOVER MORE</div> */}


                                        <div className="social-column-links">

                                            <div className="dropdown-column-title">DISCOVER MORE</div>
                                            <div className="media_links">
                                                <div className="dropdown-column-title">VIEW MORE</div>

                                                <div >
                                                    <a href="https://www.facebook.com/Mindfulmemorials/" target="_blank" ><span uk-icon="icon: facebook; ratio: 1"></span> </a>

                                                    <a href="https://www.instagram.com/mindfulmemorials/" target="_blank"><span uk-icon="icon: instagram; ratio: 1"></span></a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="uk-child-width-1-3@s uk-text-center discover-more-container" data-uk-grid>
                                            {
                                                data.strapiMainMenu.Discovermore.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <Link to={"/" + item.Url} className="discover-more-card uk-card uk-padding-remove">
                                                                <div className="uk-card-media-top discover-more-img-container">
                                                                    {/* <img src={item.Image.publicURL} className="" alt={item.Image.publicURL}></img> */}
                                                                    <Img fluid={item.Image?.localFile.childImageSharp.fluid || process.env.GATSBY_DEFAULT_NA_IMAGE} alt={item.Title}></Img>
                                                                    {/* <Img fluid={item.Image.url} alt={item.Title}></Img> */}
                                                                </div>
                                                                <div className="discover-more-title-container uk-card-body uk-padding-remove">
                                                                    <div className="card-title">{item.Title}</div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* {data.strapiMainMenu.Menu.map((item, i) => {

                                    if(i===0){
                                        return (

                                            <li key={i} className={("/"+item.URL === url)?"selected":""} >

                                                <Link to={`/${item.URL}`} >
                                                {item.Label}
                                                </Link>

                                            </li>
                                        )
                                    }else{return("")}
                                    
                                
                                })
                                
                            } */}

                            <li><Link to={"/how-can-we-help"}>Key Information <span className="link-dropdown-arrow" uk-icon="icon: chevron-down; ratio: 1"></span></Link></li>

                            <div id="drop-down-menu" className="uk-navbar-dropdown" data-uk-drop="offset: 0; boundary: !nav; boundary-align: true; pos: bottom-justify; delay-hide:100;">
                                <div className="uk-navbar-dropdown-grid uk-child-width-1-2" data-uk-grid>
                                    <div className="uk-navbar-dropdown-grid uk-child-width-1-2" data-uk-grid>
                                        <ul className="uk-nav uk-navbar-dropdown-nav">
                                            <div className="dropdown-column-title">GETTING IN TOUCH</div>
                                            {data.strapiMainMenu.KeyInformation.Column1.map((item, index) => {
                                                return (<li key={index}><Link to={"/" + item.URL.replace(/\s+/g, '-').toLowerCase()}>{item.Link}</Link></li>)
                                            })}

                                        </ul>
                                        <ul className="uk-nav uk-navbar-dropdown-nav">
                                            <div className="dropdown-column-title">SERVICES &amp; SUPPORT</div>
                                            {data.strapiMainMenu.KeyInformation.Column2.map((item, index) => {
                                                return (<li key={index}><Link to={"/" + item.URL.replace(/\s+/g, '-').toLowerCase()}>{item.Link}</Link></li>)
                                            })}
                                        </ul>
                                    </div>
                                    <div>
                                        <div className="social-column-links">

                                            <div className="dropdown-column-title">DISCOVER MORE</div>





                                            <div className="media_links">
                                                <div className="dropdown-column-title">VIEW MORE</div>

                                                <div >
                                                    <a href="https://www.facebook.com/Mindfulmemorials/" target="_blank" ><span uk-icon="icon: facebook; ratio: 1"></span></a>

                                                    <a href="https://www.instagram.com/mindfulmemorials/" target="_blank"><span uk-icon="icon: instagram; ratio: 1"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-child-width-1-3@s uk-text-center discover-more-container" data-uk-grid>
                                            {
                                                data.strapiMainMenu.KeyInformation.DiscoverMore.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <Link to={"/" + item.Url} className="discover-more-card uk-card uk-padding-remove">
                                                                <div className="uk-card-media-top discover-more-img-container">
                                                                    {/* <img src={item.Image.publicURL} className="" alt={item.Image.publicURL}></img> */}
                                                                    <Img fluid={item.Image.localFile === null ? DefaultMaterialImage : item.Image.localFile.childImageSharp.fluid} alt={item.Title} objectFit="fill"></Img>
                                                                    {/* <Img fluid={item.Image.url} alt={item.Title} objectFit="fill"></Img> */}
                                                                </div>
                                                                <div className="discover-more-title-container uk-card-body uk-padding-remove">
                                                                    <div className="card-title">{item.Title}</div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>

                            {data.strapiMainMenu.Menu.map((item, i) => {

                                return (

                                    <li key={i} className={("/" + item.URL === url) ? "selected" : ""} >

                                        <Link to={`/${item.URL}`} >
                                            {item.Label}
                                        </Link>

                                    </li>
                                )


                            })

                            }

                            </ul>


                            {(data.strapiMainMenu.CTA !== undefined) ?
                            <a className="nav-cta cta-primary-btn accent" href={"/" + data.strapiMainMenu.CTA.URL}>{data.strapiMainMenu.CTA.Label}</a>
                            : ""
                            }
                            
                            <div className="product-add-btn">
                                <a onClick={() => {
                                //toggleAddedToBasket()
                                ////handleGtag(page.Name)
                                }} href="#enquiry-popup" aria-label="enquiry button">
                                    <button className="small-btn-form-action">Enquiry</button>
                                </a>
                            </div>
                        </div>


                        <div className="uk-navbar-right uk-hidden@m nav-mobile-bar">
                            {/* <BasketWidget></BasketWidget> */}
                            <div className="product-add-btn">
                                <a onClick={() => {
                                //toggleAddedToBasket()
                                ////handleGtag(page.Name)
                                }} href="#enquiry-popup" aria-label="enquiry button">
                                    <button className="small-btn-form-action">Enquiry</button>
                                </a>
                            </div>
                            <a href="#offcanvas-usage" className="uk-navbar-toggle menuMobileBtn" data-uk-toggle="target: #offcanvas-menu">
                                <span className="uk-margin-small-right">Menu</span>
                                <span data-uk-navbar-toggle-icon>&nbsp;</span>
                            </a>
                        </div>

                        <div id="offcanvas-menu" data-uk-offcanvas="overlay: true; flip: true; mode: reveal">
                            <div className="uk-offcanvas-bar uk-flex uk-flex-column">

                                <button className="uk-offcanvas-close" type="button" data-uk-close aria-label="close button"></button>

                                {/* <SearchWidget data={data.allJrmProducts.nodes}></SearchWidget> */}

                                <ul className="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical uk-nav-parent-icon" data-uk-nav="multiple: true">
                                    <li><Link to="/">Home</Link></li>
                                    {data.strapiMainMenu.MobileMenu.map((item, i) => {
                                        return (

                                            <li key={i}>
                                                <Link to={`/${isSlugExisting(item.URL)}`}>
                                                    {item.Link}
                                                </Link>
                                                {/* {(item.Submenu.length > 0)?
                                        <ul className="uk-nav-sub">
                                            {item.Submenu.map((subitem, i) => {
                                                return( 
                                                    <li key={subitem.id}><Link to={`/${item.URL + "/" + subitem.URL}`} >{subitem.Label}</Link></li>
                                                )
                                            })}
                                            
                                        </ul>
                                        :""
                                    } */}

                                            </li>




                                        )
                                    })}
                                </ul>
                            </div>
                        </div>





                    </nav>
                </div>
            </div>


        </div>
    )
}

export default Nav