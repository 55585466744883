/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"




import "../scss/site.scss"
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons';

import Header from "./header"
import Nav from "./nav"
import Footer from "./footer"

import CookieConsent from "react-cookie-consent"






const Layout = ({ children, pageData, headerShow, headerType, eventDate, shareOnSocial}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
    
  `)

  UIkit.use(Icons);

  return (
    <>
      
        <Nav />
        {(headerShow === true)?
        <Header siteTitle={data.site.siteMetadata.title} pageData={pageData} headerType={headerType} eventDate={eventDate} shareOnSocial={shareOnSocial}/>:
        ""
        }
        <main>{children}</main>
        <Footer/>
        <CookieConsent 
          // debug="true"
          location="bottom"
          cookieName="mindfulmemorialsCookie"
          style={{background: "#D4DA8A", color: "#232826"}}
          buttonStyle={{background: "#232826", color: "#fff"}}
          expires={150}
          
          
          >This website is using cookies to deliver you a better user experience. <Link to="/cookie" style={{color: "#232826", textDecoration: "underline"}}>Find out more</Link></CookieConsent>
      
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
