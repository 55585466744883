import React, { useContext, useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import useSiteMetadata from "../utils/useSiteMetadata"
import { removeSlashFromSlug } from '../utils/additionalFunctions';
// import Signup from "../components/signup";
// import Hero from "../components/basic-components/hero";
// import Tiles from "../components/tiles";
// import CustomCollectionsBar from "../components/custom-collections-bar";
// import CTAImg from "../assets/img/Help-you-scaled-e1588324743844.jpg";
// import LatestNewsCarousel from "../components/latest-news-carousel";
// import ImageCard from '../components/basic-components/image-card'

import '../scss/news-events.scss'
// import SubheadingText from "../components/basic-components/subheading-text";


import loadable from '@loadable/component'

const SubheadingText = loadable(() => import("../components/basic-components/subheading-text"))
const LatestNewsCarousel = loadable(() => import("../components/latest-news-carousel"))
const Tiles = loadable(() => import("../components/tiles"))
const Signup = loadable(() => import("../components/signup"))
const QuoteCarousel = loadable(() => import("../components/quote-carousel"))
// const uikit = loadable(() => import('uikit/dist/css/uikit.css'))
// const UIkit = loadable(() => import('uikit'))


const IndexPage = () => {
  // const {getImgUrl} = useGlobalContext()
  // getImgUrl("asd")
  // console.log(GetImgUrl("a8026070-072a-586e-b423-2c9b261c378e"))

  

  const data = useStaticQuery(graphql`
   query MyQuery  {
      strapiNewsEvents {
        SEOTitle
        SEODescription
        Canonical
        MetaTags{
          MetaName
          MetaContent
        }
        PageComponents
        Header {
          Title
          Subtitle
          Visible
          LeftBgColor
          RightBgColor
          CTA
          URL
          CTASecondary
          URLSecondary
          Subheading
          SubheadingSummary
          Background {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }

      allStrapiNewsArticle(sort: {order: DESC, fields: created_at}) {
        edges {
          node {
            Components
            Title
            slug
            Header {
              LeftBgColor
              RightBgColor
              Subtitle
              Background {
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 284,  maxHeight: 185,) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }

  
      allStrapiEvents( filter: {fields: {timestamp: {gt: 1634714790}}} sort: {order: ASC, fields: Date}) {
        edges {
          node {
            Date(formatString: "DD/MM/YYYY")
            Title
            slug
            Header {
              LeftBgColor
              RightBgColor
              Background {
                localFile {
                  absolutePath
                  publicURL
                  childImageSharp {
                    fluid(quality: 90, maxHeight: 185, maxWidth: 359) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);


  var headerData = {
    headerTitle: data.strapiNewsEvents.Header.Title,
    headerDescription: data.strapiNewsEvents.Header.Subtitle,
    headerCTAPrimaryLabel: data.strapiNewsEvents.Header.CTA||"",
    headerCTAPrimaryUrl: data.strapiNewsEvents.Header.URL||"",
    headerCTASecondaryLabel: data.strapiNewsEvents.Header.CTASecondary||"",
    headerCTASecondaryUrl: data.strapiNewsEvents.Header.URLSecondary||"",
    headerBgImage: data.strapiNewsEvents.Header.Background.localFile.childImageSharp.fluid,
    headerLeftBgColor: data.strapiNewsEvents.Header.LeftBgColor ? data.strapiNewsEvents.Header.LeftBgColor : '',
    headerRightBgColor: data.strapiNewsEvents.Header.RightBgColor ? data.strapiNewsEvents.Header.RightBgColor : '',
  };


  //Filter events based on date

    let today = new Date();
    let day = today.getDate()
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    let fullDate = (year + '/' + month + '/' + day)

    
    let currentUnixDate = new Date(fullDate).getTime() / 1000;

    let filteredEventsByDate =  data.allStrapiEvents.edges.filter(e => {
     
      let eventDate = e.node.Date
      let reverseEventDate = eventDate.split('/').reverse().join('/')
      let unixDateEvent = new Date(reverseEventDate).getTime() / 1000;
      
      let events
      
     
      return   events =  unixDateEvent >= currentUnixDate
      
    })

    useEffect(() => {
      filteredEventsByDate.forEach(item => {
        item.node.slug = item.node.slug.toLowerCase()
       
      })
    }, [])

  /* <ImageCard
     img={GetImgUrl(item.ImageCarousel.Images.localFile___NODE).fluid}
     type={"normal"}
     title={item.Title}
     description={item.Text}
   />;*/

  /*
  data.strapiHomepage.HomepageSections {
    item.CTA !== undefined && item.CTA.length === 1 ? (
      <Tiles data={item.CTA} singleFlag={true}></Tiles>
    ) : (
      ""
    )
    // (item.CTA !== null && Object.keys(item.CTA).length === 1)?<Tiles data={item.CTA} singleFlag={true}></Tiles>:""
    //don't forget to fix this once the cta.length of a json (dynamic zone) is found
  } */
    const { siteUrl: url } = useSiteMetadata();

  return (
    <div>
      <Layout
        pageData={headerData}
        headerShow={data.strapiNewsEvents.Header.Visible}
        headerType="contact"
      >
        <SEO title={data.strapiNewsEvents.SEOTitle?data.strapiNewsEvents.SEOTitle:"News &amp; Events"} 
         description={data.strapiNewsEvents.SEODescription} 
         meta={data.strapiNewsEvents.MetaTags}
         canonical={data.strapiNewsEvents.Canonical ? data.strapiNewsEvents.Canonical : `${url + '/news-events/'}`}
         />

        {
          data.strapiNewsEvents.Header?.SubheadingSummary?
          <SubheadingText subheading={data.strapiNewsEvents.Header.Subheading} summary={data.strapiNewsEvents.Header.SubheadingSummary} endLine={false}/>
          :""
        }

        {/* <Tiles></Tiles> */}

        {/* <Carousel title={carouselContents.carouselTitle}></Carousel> */}
        {/* <Carousel title={"Test news"} data={data.allStrapiNewsArticle.edges.nodes} url="/collections"></Carousel> */}

        {/* <CustomCollectionsBar title={"Test news"} data={data.allStrapiNewsArticle} url="/collections"></CustomCollectionsBar> */}
        
        {/* <div className='divider-wrapper'>
          <div className='divider-small'/>
        

       
        </div> */}
        <LatestNewsCarousel
          title={null}
          data={data.allStrapiNewsArticle}
          url={`/news`}
          component={'news'}
        ></LatestNewsCarousel>

        {data.strapiNewsEvents.PageComponents.map((item, index) => {

          return (
            <>
              {/* <h2>{data.strapiEvents.Title}</h2> */}

              

              {
                item.CTA !== undefined && item.CTA.length === 1 ? (
                  <Tiles key={index} data={item.CTA} singleFlag={true} type='normal' width='full' ></Tiles>
                ) : (
                   <Tiles key={index} data={item.CTA}></Tiles>
                )
                // (item.CTA !== null && Object.keys(item.CTA).length === 1)?<Tiles data={item.CTA} singleFlag={true}></Tiles>:""
                //don't forget to fix this once the cta.length of a json (dynamic zone) is found
              }
           
              {item.Testimonial 
                  ? (<div className="">
                      <QuoteCarousel quoteList={item.Testimonial}></QuoteCarousel>
                    </div>
                    ) 
                  : (
                    ""
                  )}
           
            </>
          );
        })}
  
  



        {/* <LatestNewsCarousel
          title="Test Events"
          data={data.allStrapiEvents}y
          urlCategory="events/"
          url="/events"
        ></LatestNewsCarousel> */}

        {/* this is the collection bar on the homepage that comes from the user selection relation in the top right dropdown in strapi */}
        {/* <CustomCollectionsBar title={"Memorials for all"} data={data.strapiNewsEvents.CustomCollection} url="/collections"></CustomCollectionsBar> */}

        {/* <Tiles data={data.strapiNewsEventsCta.HomepageCTA}></Tiles> */}
       <div className='divider-wrapper'>
          <div className='divider-small'/>


        {/*  title={data.strapiNewsEvents ? data.strapiNewsEvents.Header.Title: 'Upcoming Events at Mindful Memorials'} title for event tiles  section*/}
        {
          data.allStrapiEvents.edges.length >= 1 ? (
            <div className="padding-bottom">
            {/*  filteredEventsByDate in loc de data.allStrapiEvents.edges*/}

        <Tiles title={'Upcoming Events at Mindful Memorials'} data={filteredEventsByDate} url={'/events'} singleFlag={true} type='horizontal' width='half' ></Tiles>
            </div>
          ) : ''
        }
</div>
        {/* {data.strapiNewsEvents.HomepageSections.map((item, index) => {


        return(
          <>
            {(item.CTA !== undefined && item.CTA.length > 1 )?<Tiles data={item.CTA}></Tiles>:""}
            
            {
            (item.CTA !== undefined && item.CTA.length === 1)?<Tiles data={item.CTA} singleFlag={true}></Tiles>:""
            // (item.CTA !== null && Object.keys(item.CTA).length === 1)?<Tiles data={item.CTA} singleFlag={true}></Tiles>:""
            //don't forget to fix this once the cta.length of a json (dynamic zone) is found
            }

            {(item.Hero)?<Hero 
            title={item.Hero.Title}
            description={item.Hero.Description}
            label={item.Hero.Label}
            url={item.Hero.url}
            ></Hero>:""}
          </>
        )
      })} */}

        {/* old hero kept for posterity. will be removed */}
        {/* <Hero 
        title={"Welcome to Mindful Memorials"}
        description={"Aute reprehenderit aliquip exercitation tempor enim aliqua dolore. Tempor nulla quis sunt laborum mollit ut sint do elit cillum. Eiusmod sint culpa labore minim nulla exercitation et aute excepteur ipsum qui aute exercitation quis."}
        label={"Find our more"}
        url={"/collections"}
        ></Hero> */}

        <Signup
          title="Sign Up to Our Newsletter"
          description="Get updates on all the lastest information, or stay informed about our courses"
          buttonLabel="Sign Up"
        />
      </Layout>
    </div>
  );
};

export default IndexPage;
