
const removeSlashFromSlug = (slug, addtrail = 0) => {
//function  {

  if (slug && slug[0] === '/') {
    slug = slug.substring(1)
  }
  if (addtrail == 1 && slug && slug.length > 1 && slug[slug.length - 1] != "/") {
    slug = slug + '/';
  }
  if (slug) {
    slug = slug.replace(/[\s+.]/g, "-").replace(/[*+~.,_^&#£%|()'"?!:@]/g, '').toLowerCase();
  }
  return slug;
};


module.exports = {
  removeSlashFromSlug
};



