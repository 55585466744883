import React from "react"
import CustomMarkdown from "../../utils/customMarkdown"

import "../../scss/components/subheading-text.scss"





const SubheadingText = ({text, subheading, summary, endLine=true}) => {

    return(
    <section className="subheading-text padding-top lateral-padding">
        <div className="text-container">
            <CustomMarkdown className='react-markdown-element'>{text}</CustomMarkdown>
            <div className="subheading">{subheading}</div>
            <CustomMarkdown className='react-markdown-element'>{summary}</CustomMarkdown>
            {endLine?<hr/>:""}
            
        </div>
    </section>
    )
    
}

export default SubheadingText