import React from "react"
import {Link} from "gatsby"
import {FaAngleDown} from "react-icons/fa"




const CategoryBar = ({data}) => {

return (
    <div className="category-container">
        <div className="category-wrapper">
            <div className="category-width uk-child-width-expand@m r uk-text-center@l .uk-text-left@s uk-grid-collapse" data-uk-grid>
                {data.slice(0, 7).map((item, i) => {
                    return(  
                        <div key={i}>
                            <Link to={"/product-list/"+item.Name.replace(/\s+/g, '-').toLowerCase()} className="uk-card uk-card-body uk-padding-remove category-link">{item.Name.toLowerCase()}<span className="link-dropdown-arrow"><FaAngleDown></FaAngleDown></span></Link>
                        </div>
                    )
                })}
            </div>
        </div>
    </div>
)
}

export default CategoryBar