import React, { useState } from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
// import Button from "../components/basic-components/button"

import loadable from '@loadable/component'
import { graphql } from "gatsby"




const Button = loadable(() => import("../components/basic-components/button"))

// import type1 from "../assets/img/type1.png"
// import type2 from "../assets/img/type2.png"
// import type3 from "../assets/img/type3.png"
// import type4 from "../assets/img/type4.png"
// import type5 from "../assets/img/type5.png"

// import materialGranite from "../assets/img/materials/granite.png"
// import materialStone from "../assets/img/materials/stone.png"

// import matSel1 from "../assets/img/materials/black-galaxy.png"
// import matSel2 from "../assets/img/materials/portland-limestone.png"
// import matSel3 from "../assets/img/materials/york-stone.png"
// import matSel4 from "../assets/img/materials/ananthapur-grey.png"
// import matSel5 from "../assets/img/materials/black-galaxy.png"
// import matSel6 from "../assets/img/materials/silver-grey.png"
// import matSel7 from "../assets/img/materials/south-african-grey.png"
// import matSel8 from "../assets/img/materials/indian-grey.png"
// import matSel9 from "../assets/img/materials/kg-grey.png"
// import matSel10 from "../assets/img/materials/cera-grey.png"
// import matSel11 from "../assets/img/materials/kuru-grey.png"
// import matSel12 from "../assets/img/materials/antique-brown.png"




// export const query = graphql`
// query customCollectionsQuery($ItemList: [String]) {
//   allJrmProducts(filter: {Reference: {in: $ItemList}}) {
//     nodes {
//       Id
//       Name
//       SDItemCategoryId
//       AttractivePrice
//       Description
//       Slug
//     }
//   }

//   allJrmCategories(limit: 7) {
//     nodes {
//       Id
//       Name
//     }
//   } 
// }
// `

//test

export const query = graphql`
{
  strapiContactPage {
    Address
    BranchName
    ContactFormToken
    Email
    Subheader
    MapImage {
      localFile{
        publicURL
      }
    }
    HeaderImage {
      localFile{
        publicURL
        childImageSharp{
          fluid(quality: 90, maxWidth: 1900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    PhoneNo
    strapiId
    OpeningHours
  }



  
}
`









const GetaQuote = ({data}) => {


    const sendQuote = event =>{
      event.preventDefault();

      // console.log(cform);
      var form = document.getElementById("getquoteform")
      var cform = {
        // materialType: "selected material type",
        cemetery: form.elements["getaquote-cemetery-name"].value,
        city: form.elements["getaquote-town-city"].value,
        // materialType: document.getElementById("order-email").value
        additionalInfo: form.elements["getaquote-additional-info"].value,
        fullName: form.elements["getaquote-fullname"].value,
        email: form.elements["getaquote-email"].value,
        phoneNo: form.elements["getaquote-telephone"].value,
        howReached: form.elements["getaquote-how-reached"].value,
        acceptOffers: form.elements["getaquote-offers"].checked,
      }

      // console.log(form.elements["product-type"].value + " " +  form.elements["material-type"].value) //returns selected radio option

      

      var submitBtn = document.getElementById("confirm-quote").firstChild;
      submitBtn.innerHTML = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="spinner" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg>';
      submitBtn.classList.add("form-submitting");

      var body = {
        Token: data.strapiContactPage.ContactFormToken,
        FormData: JSON.stringify(cform)   
      };

      

      // console.log(body)

      fetch("https://api.phaeria.com/api/home/SendEmailNotification", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => 
        response.text().then(function (responseText) {
          // console.log("response text: " + responseText);
          if(responseText.localeCompare('"Form sent successfully."') === 0){
            //success send message
            // console.log("success"+ responseText);
            submitBtn.innerHTML = "Thanks for your request, we’ll be in touch shortly.";
            submitBtn.classList.remove("form-submitting");
          }else{
            //error sending message
            // console.log("something went wrong");
            submitBtn.innerHTML = "Oops... Try again!";
            submitBtn.classList.remove("form-submitting");
          }
            

        })
      )
      //end of fetch

      // console.log("Message sent");

      
    }



  


    return(
    <div>
      <Layout headerShow={false}>
        <SEO title={"Make an appointment"} />

        
        <section className="get-a-quote contact-form-section padding-top padding-bottom">

          {/* <div className="quote-navigator uk-text-center">Quote step navigator</div> */}

          <div className="quote-pages-container">

            <form id="getquoteform" onSubmit={sendQuote}>

            
            {/* <div id="quote-step-1">

              <div className="quote-section-title">Please select a product type</div>

              <div className="options-list">

                <ul id="product-type" className=" uk-grid-small uk-child-width-1-5@s" data-uk-grid>

                  <li className="">
                    
                        <label>
                          <input type="radio" id="r1" name="product-type" value="Head Stone" required></input>
                          <img src={type1} className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">Head Stone</div>
                        </div>
                   
                  </li>
                  <li className="">
                    
                        <label>
                          <input type="radio" id="r1" name="product-type" value="Plaques"></input>
                          <img src={type2} className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">Plaques</div>
                        </div>
                   
                  </li>
                  <li className="">
                    
                        <label>
                          <input type="radio" id="r1" name="product-type" value="Vases"></input>
                          <img src={type3} className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">Vases</div>
                        </div>
                   
                  </li>
                  <li className="">
                    
                        <label>
                          <input type="radio" id="r1" name="product-type" value="Kerb Set"></input>
                          <img src={type4} className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">Kerb Set</div>
                        </div>
                   
                  </li>
                  <li className="">
                    
                        <label>
                          <input type="radio" id="r1" name="product-type" value="Bespoke"></input>
                          <img src={type5} className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">Bespoke</div>
                        </div>
                   
                  </li>
                 

                </ul>

              </div>



              <div className="quote-section-title">Choose a material</div>

              <div className="options-list">

                <ul id="material-type" className=" uk-grid-small uk-child-width-1-2@s" data-uk-grid>

                  <li className="uk-card">
                    
                        <label>
                          <input type="radio" id="r1" name="material-type" value="Granite"></input>
                          <img src={materialGranite} width="240" className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">Granite</div>
                        </div>
                   
                  </li>
                  <li className="uk-card">
                    
                        <label>
                          <input type="radio" id="r1" name="material-type" value="Stone"></input>
                          <img src={materialStone} width="240" className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">Stone</div>
                        </div>
                   
                  </li>
                  
                 

                </ul>

              </div>


              <div className="divider"></div>


              <div className="options-list">

                <ul id="material-selection" className=" uk-grid-small uk-child-width-1-6@s" data-uk-grid>

                  <li className="uk-card">
                    
                        <label>
                          <input type="radio" id="r1" name="material-selection" value="Honed Black Granite"></input>
                          <img src={matSel1} className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">Honed Black Granite</div>
                        </div>
                      
                  </li>
                  <li className="uk-card">
                    
                        <label>
                          <input type="radio" id="r1" name="material-selection" value="Portland Limestone"></input>
                          <img src={matSel2} className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">Portland Limestone</div>
                        </div>
                      
                  </li>
                  <li className="uk-card">
                    
                        <label>
                          <input type="radio" id="r1" name="material-selection" value="York Stone"></input>
                          <img src={matSel3} className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">York Stone</div>
                        </div>
                      
                  </li>
                  <li className="uk-card">
                    
                        <label>
                          <input type="radio" id="r1" name="material-selection" value="Ananthapur Grey"></input>
                          <img src={matSel4} className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">Ananthapur Grey</div>
                        </div>
                      
                  </li>
                  <li className="uk-card">
                    
                        <label>
                          <input type="radio" id="r1" name="material-selection" value="Black Galaxy"></input>
                          <img src={matSel5} className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">Black Galaxy</div>
                        </div>
                      
                  </li>
                  <li className="uk-card">
                    
                        <label>
                          <input type="radio" id="r1" name="material-selection" value="Silver Grey"></input>
                          <img src={matSel6} className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">Silver Grey</div>
                        </div>
                      
                  </li>
                  <li className="uk-card">
                    
                        <label>
                          <input type="radio" id="r1" name="material-selection" value="South African Grey"></input>
                          <img src={matSel7} className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">South African Grey</div>
                        </div>
                      
                  </li>
                  <li className="uk-card">
                    
                        <label>
                          <input type="radio" id="r1" name="material-selection" value="Indian Grey"></input>
                          <img src={matSel8} className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">Indian Grey</div>
                        </div>
                      
                  </li>
                  <li className="uk-card">
                    
                        <label>
                          <input type="radio" id="r1" name="material-selection" value="KG Grey"></input>
                          <img src={matSel9} className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">KG Grey</div>
                        </div>
                      
                  </li>
                  <li className="uk-card">
                    
                        <label>
                          <input type="radio" id="r1" name="material-selection" value="Cera Grey"></input>
                          <img src={matSel10} className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">Cera Grey</div>
                        </div>
                      
                  </li>
                  <li className="uk-card">
                    
                        <label>
                          <input type="radio" id="r1" name="material-selection" value="Kuru Grey"></input>
                          <img src={matSel11} className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">Kuru Grey</div>
                        </div>
                      
                  </li>
                  <li className="uk-card">
                    
                        <label>
                          <input type="radio" id="r1" name="material-selection" value="Antique Brown"></input>
                          <img src={matSel12} className="" alt=""></img>
                        </label>
                        <div className="uk-card-body uk-padding-remove">
                            <div className="card-title">Antique Brown</div>
                        </div>
                      
                  </li>
                 
                  
                  
                 
                </ul>

              </div>
            </div> */}

            <hr/>


            <div id="quote-step-2">
              <div className="quote-section-title">Make an appointment</div>

              <div className="additional-info-box">
                <h3>Location for Memorial</h3>
                <hr/>
                <p>Please let us know the location that the Memorial will need to be installed. If you don’t know at this stage please ignore this section. </p>
                <fieldset className="uk-fieldset">
                    <div className="uk-margin uk-width-1-1">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Name of the cemetery / Church yard</label>
                        <input id="getaquote-cemetery-name" className="uk-input" type="text" placeholder="" />
                    </div>
                    <div className="uk-margin uk-width-1-1">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Town/City</label>
                        <input id="getaquote-town-city" className="uk-input" type="text" placeholder="" />
                    </div>
                </fieldset>
              </div>

              <div className="additional-info-box">
                <h3>Additional information</h3>
                <hr/>
                <p>Please let us know If there is any further information that you would like to let us know at this time.</p>
                <fieldset className="uk-fieldset">
                    <div className="uk-margin uk-width-1-1">
                        <label className="uk-form-label" htmlFor="form-stacked-text"></label>
                        <textarea id="getaquote-additional-info" className="uk-input" name="text" rows="5" placeholder="Add more information" ></textarea>
                    </div>
                    
                </fieldset>
              </div>


            </div>

            {/* <hr/> */}

            <div id="quote-step-3">

              <div className="quote-section-title">Your Details</div>

              <div className="additional-info-box">
                <h3>Your Details</h3>
                <hr/>
                
                <fieldset className="uk-fieldset">
                    <div className="uk-margin uk-width-1-1">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Full Name</label>
                        <input id="getaquote-fullname" className="uk-input" type="text" placeholder="" />
                    </div>
                    <div className="uk-margin uk-width-1-1">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Email address</label>
                        <input id="getaquote-email" className="uk-input" type="text" placeholder="" />
                    </div>
                    <div className="uk-margin uk-width-1-1">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Telephone Number</label>
                        <input id="getaquote-telephone" className="uk-input" type="text" placeholder="" />
                    </div>
                    <div className="uk-margin uk-width-1-1">
                          <label className="uk-form-label" htmlFor="form-stacked-text">How would you like to be reached?</label>
                          <select id="getaquote-how-reached" className="uk-select" type="text" placeholder="">
                            <option>By phone</option>
                            <option>By email</option>
                          </select>
                    </div>
                    <div className="uk-margin uk-width-1-1">
                        <label className="uk-form-label" htmlFor="form-stacked-text">
                          <input type="checkbox" id="getaquote-offers" name="product-type" value="Head Stone" ></input>
                          We’d like to send you further information about our products and services, including special offers and recommendations. To receive these occasional emails, check this box. 
                        </label>
                        
                    </div>
                </fieldset>
              </div>


              <div className="product-add-btn uk-text-center">
                    <div id="confirm-quote"><Button use="" label="Make an appointment"></Button></div>
              </div>
            </div>


            

            </form>
          </div>

        </section>
  
        
  
      </Layout>
  
  
      
    </div>
    )
}
  
  export default GetaQuote