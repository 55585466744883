import React, { useEffect, useRef } from "react"
import { connect } from 'react-redux';
import { Link } from "gatsby"
import { navigate } from "gatsby-link";
import SEO from "../components/seo"
import Layout from "../components/layout"
// import Carousel from "../components/carousel"
// import CategoryBar from "../components/category-bar"
import DefaultImag from "../assets/img/e-04w.jpg"
import Button from "../components/basic-components/button";
import useSiteMetadata from "../utils/useSiteMetadata"

import { getAllProducts } from '../store/selectors';
import { removeFromCartMessage, emptyCartMessage, updateProductCountMessage } from '../store/actions';



var orderProducts = []



  function prepopulateForm(){
    document.getElementById("order-email").value = "test@email.com"
    document.getElementById("order-first-name").value = "John"
    document.getElementById("order-surname").value = "Smith"
    document.getElementById("order-salutation").value = "Mr. John Smith"
    document.getElementById("order-phone").value = "123234235235"
    document.getElementById("order-address").value = "london test address"
    document.getElementById("order-postcode").value = "2345rfes"
  }


const Checkout = ({data, products, removeFromCart, emptyCart, updateProductCount}) => {

    const checkoutResponse = useRef();
    const checkoutBasketContents = useRef();
    const checkoutCustomerDetails = useRef();

    //console.log(products + " products-checkout")
   




    const sendOrderTest = event =>{

        var order = {
    
            "Customer": {
                "Email": document.getElementById("order-email").value,
                "Salutation": document.getElementById("order-salutation").value,
                "FirstName": document.getElementById("order-first-name").value,
                "Surname": document.getElementById("order-surname").value,
                "Address": document.getElementById("order-address").value,
                "Postcode": document.getElementById("order-postcode").value,
                "Mobile": document.getElementById("order-phone").value,
                "BillingContactName": document.getElementById("order-first-name").value,
                "BillingSalutation": document.getElementById("order-salutation").value,
                "BillingAddress": document.getElementById("order-address").value,
                "BillingPostcode": document.getElementById("order-postcode").value,
                "Cemetery": document.getElementById("order-cemetery").value
            },
    
            // "Products": [{
            //     "ItemId": "13",
            //     "MaterialId": "34",
            //     "SizeId": "14",
            //     "Quatity": "2"
            // }],
            "Cemetery": document.getElementById("order-cemetery").value,
            "Products": orderProducts,
    
            "TotalPrice": "0.00"
        }
    
        //   fname: document.getElementById("fname").value,
        //   email: document.getElementById("email").value,
        //   message: document.getElementById("message").value
        
        // console.log(order);
        var submitBtn = document.getElementById("confirm-order").firstChild;
        submitBtn.innerHTML = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="spinner" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg>';
        submitBtn.classList.add("form-submitting");
        // document.getElementById("confirm-order").firstChild.classList.add("form-submitting");
      
        var body = {
          data: JSON.stringify(order)   
        };
    
        fetch(process.env.GATSBY_CHECKOUT_FETCH_ADDRESS, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json'
          }
          
        }).then(response => 
         {
            //  console.log(response)
            //  console.log("status" + response.status)
            //  console.log("quoteNo" + response.quoteNo)
            // console.log("response: " + response.ok)
            if(!response.ok){
                // throw new Error('network response was not OK')
                // console.log("error sending")
                submitBtn.innerHTML = "Oops... Try again!"
                submitBtn.classList.remove("form-submitting")
    
            }else{
                //console.log(document.getElementById("checkout-response"), "tets")
                response.json().then(json => {
                    if(json.status === "Success"){
                        // console.log("sent ok")
                       
                        submitBtn.innerHTML = "Quotation requested!"
                        document.getElementById("order-response-number").innerHTML = json.quoteNo
                        // document.getElementById("order-response-payment-url").innerHTML = json.onlinePaymentUrl
                        document.getElementById("checkout-title").classList.add("uk-hidden")
                        submitBtn.classList.remove("form-submitting")
                        checkoutResponse.current.firstChild.classList.remove("uk-hidden")
                        checkoutBasketContents.current.firstChild.classList.add("uk-hidden")
                        checkoutCustomerDetails.current.firstChild.classList.add("uk-hidden")
                        //emptyCart()
                        emptyCart({})


                        
                        if(json.onlinePaymentUrl){
                            navigate(json.onlinePaymentUrl)
                        }
                        

                        
                        
                    }
                    else{
                        // console.log("sent but not ok: " + json.message)
                        submitBtn.classList.remove("form-submitting")
                        submitBtn.classList.add("red-state")
                        submitBtn.innerHTML = "Something went wrong... Try again!"
    
                    }
                })
    
                
            }
    
            
    
         }
        )
        // .then(result => console.log("result" + result.json()))
        // console.log("Message sent");
      
        event.preventDefault();

        
      }


 
    function handleIncrement(item) {
        // removeFromCartMessage(item)
        // addToCartMessage(item, 5)
        
        // console.log(addToCartMessage(item, 3))
        updateProductCount({id:item.id, count:1})

    }
    
    function handleDecrement(item) {
        // addToCartMessage(item, 5)
        
        // console.log(addToCartMessage(item, 2))
        updateProductCount({id:item.id, count:-1})
    }
  

    let allProductsWithHidePrice = 0;

    for(const item of products) {
        if(item.product.hidePrice) {
            allProductsWithHidePrice += 1;
        }
    }

    let total = 0;
     products.map((item) => {
        const {count, product:{price}} = item
        return total += price * count
    })

  useEffect(() => {
    //Andrei: ignore the PPC conversion (client request)
//       if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined'){
//          window.gtag('event', 'conversion', {'send_to': 'AW-10835581327/ioZLCMWRqrMDEI-z564o',
//     'value': `${total}`,
//     'currency': 'GBP'
//   });
//     }


      if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined'){
        
        window.gtag('event', 'complete_order', {'value': `${total}`, 'currency': 'GBP'})
    }

    
  }, [])

  const { siteUrl: url } = useSiteMetadata();
    
    return(
    <div>
      <Layout headerShow={false}>
        <SEO title={"Checkout"} canonical={ `${url + "/checkout"}`}/>

        <section id="checkout-title" className="checkout-title uk-text-center padding-top">
            <div>
                <h1>Checkout</h1>
            </div>
        </section>

        {/* <section>
            {products.map(({ product, count },i) => (
                <div>
                    <a key={i} href="#0" >{product.name} - {count}</a>
                    <a href="#0" onClick={() => removeFromCart(product)}>remove</a>
                </div>
            ))}
        </section> */}

        {/* {console.log(products.length)} */}

        <section id="checkout-basket-contents " ref={checkoutBasketContents} >
            <div className={"checkout-basket-contents " + ((products.length === 0)?"uk-hidden":"")}>
                <h2>Your cart</h2>
                <div className="divider"></div>
                <div className="uk-grid-small" data-uk-grid>

                    {products.map(({ product, count },i) => {

                        orderProducts.push({
                            "ItemId":product.itemId,
                            "MaterialId":product.materialId,
                            "SizeId":product.sizeId,
                            "Quantity": 1,
                            "HidePrices":product.hidePrice
                        })
                        {/* console.log("order products: ", orderProducts) */}
                        

                        return(
                            <div key={i} className="product-card uk-margin-small uk-width-1-1">
                                {/* <ImageCard type="checkout-horizontal" title={product.name} quantity={count} price={product.price} data={product}></ImageCard> */}
                                
                                <div className="image-card uk-card uk-card-default uk-grid-collapse " data-uk-grid>
                                    <div className="image-holder uk-flex-first@s  uk-cover-container uk-width-1-4@m uk-width-1-4@s">
                                        <img src={product.image} className="image-width-mobile-resolution" alt="" data-uk-cover></img>
                                        <canvas width="100" height="100"></canvas>
                                    </div>
                                    <div className="uk-width-3-4@m uk-width-3-4@s">
                                        <div className="checkout-prod-details">
                                        
                                            <h3 className="uk-card-title card-title">{product.name?product.name.toLowerCase():"default title"}</h3>

                                            {/* <p className="card-description">asdf | asdasf</p> */}
                                            
                                            <div className="product-quantity">
                                                <button onClick={() => handleDecrement(product)}>-</button>
                                                <span>{count?count:"N/A"}</span>
                                                <button onClick={() => handleIncrement(product)}>+</button>
                                            </div>
                                           {product.hidePrice ? <div className="card-price uk-align-right">Quote</div> : <div className="card-price uk-align-right">£{product.price?Math.round(product.price * count * 100)/100:"N/A"}</div>} 
                                            
                                        </div>
                                        
                                    </div>
                                    <button onClick={() => removeFromCart(product)} className="uk-position-top-right delete-button" data-uk-close aria-label="close-button"></button>
                                </div>

                            </div>
                        )
                    })}

                </div>
            </div>
        </section>

        <section id="checkout-customer-details " className=" padding-bottom" ref={checkoutCustomerDetails}>
            <div className="checkout-customer-details ">
                <h2>Your details</h2>
                <h5>All fields are required</h5>
                {/* <button onClick={() => prepopulateForm()}>prepopulate</button> */}
            <form className="uk-grid-large " id="memorials-checkout" onSubmit={sendOrderTest} data-uk-grid>
                <fieldset className="uk-fieldset uk-width-1-1">

                    <div className="uk-margin uk-width-1-1">
                        <label className="uk-form-label" htmlFor="form-stacked-text">First Name</label>
                        <input id="order-first-name" className="uk-input" type="text" placeholder="Example: John" required/>
                    </div>

                    <div className="uk-margin uk-width-1-1">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Surname</label>
                        <input id="order-surname" className="uk-input" type="text" placeholder="Example: Smith" required/>
                    </div>

                    <div className="uk-margin uk-width-1-1">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Salutation</label>
                        {/* <input id="order-salutation" className="uk-input" type="text" placeholder="Example: Mr. John" required/> */}
                        <select id="order-salutation" className="uk-input" placeholder="Select title">
                            <option value={""}>―Select an option―</option>
                            <option value={"Mr"}>Mr</option>
                            <option value={"Miss"}>Miss</option>
                            <option value={"Mrs"}>Mrs</option>
                            <option value={"Ms"}>Ms</option>
                            <option value={"Mr & Mrs"}>Mr &amp; Mrs</option>
                            <option value={"Dr"}>Dr</option>
                            <option value={"Rev"}>Rev</option>
                            <option value={"Professor"}>Professor</option>
                            <option value={"Lord"}>Lord</option>
                            <option value={"Lady"}>Lady</option>
                            <option value={"Sir"}>Sir</option>
                        </select>
                    </div>

                    <div className="divider"></div>

                    <div className="uk-margin uk-width-1-1">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Email address</label>
                        <input id="order-email" className="uk-input" type="text" placeholder="Example: name@example.com" required/>
                    </div>

                    <div className="uk-margin uk-width-1-1">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Phone No</label>
                        <input id="order-phone" className="uk-input" type="text" placeholder="Example: 012 3456 7890" required/>
                    </div>
                    
                    <div className="" data-uk-grid>
                        <div className="uk-margin uk-width-2-3@s">
                            <label className="uk-form-label" htmlFor="form-stacked-text">Address</label>
                            <input id="order-address" className="uk-input" type="text" placeholder="Example: London, 91 Western Road" required/>
                        </div>
    
                        <div className=" uk-width-1-3@s post-code-field">
                            <label className="uk-form-label" htmlFor="form-stacked-text">Postcode</label>
                            <input id="order-postcode" className="uk-input" type="text" placeholder="Ex: DA8 3NU" required/>
                        </div>
                    </div>

                    <div className="divider"></div>

                    <div className="uk-margin uk-width-1-1">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Cemetery/Churchyard</label>
                        <input id="order-cemetery" className="uk-input" type="text" placeholder="Cemetery name"/>
                    </div>



                    {/*
                    <div className="divider"></div>
                     <h3>Billing details</h3>

                    <div className="uk-margin uk-width-1-1">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Name</label>
                        <input id="order-first-name" className="uk-input" type="text" placeholder="Example: John Smith"/>
                    </div>

                    <div className="uk-margin uk-width-1-1">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Salutation</label>
                        <input className="uk-input" type="text" placeholder="Example: Mr. John"/>
                    </div>

                    <div className="divider"></div>

                    <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="form-stacked-text">House Number &amp; Street</label>
                        <input className="uk-input" type="text" placeholder="Example: 91 Western Road"/>
                    </div>

                    <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Postcode</label>
                        <input className="uk-input" type="text" placeholder="Example: DA8 3NU"/>
                    </div> */}







                </fieldset>
                <div className="confirm-order-checkout">
                    <div id="confirm-order"><Button use="addToCart" label={`${allProductsWithHidePrice === products.length ? 'Confirm Quote' : "Confirm Order"}`}></Button></div>
                </div>
            </form>
            </div>
        </section>
        

        <section id="checkout-response " className="padding-bottom" ref={checkoutResponse}>
            <div className="checkout-response uk-hidden padding-top padding-bottom">
                {/* <h2>Order complete</h2> */}
                <h3>Thank you for your enquiry, we are processing your quotation request and will be in touch shortly.</h3>

               

                <div className="" data-uk-grid>
                    <div className="uk-padding-remove uk-width-1-1">

                        <div className="checkout-response-field">
                            <div>Reference number: <span id="order-response-number"></span></div>
                            {/* <div><a id="order-response-payment-url">Proceed to payment</a></div> */}
                        </div>
                        <div className="divider"></div>
                        <div className="">
                            <Link to="/">
                                <Button use="" label="Continue browsing"></Button>
                            </Link>
                        </div>

                        

                    </div>
                </div>
            </div>
        </section>

      </Layout>
  
  
      
    </div>
  )
    }


function mapStateToProps({ cartReducer }) {
    return {
        products: getAllProducts(cartReducer)
    };
}
    
    const mapDispatchToProps = {
    removeFromCart: product => removeFromCartMessage(product.id),
    emptyCart: product => emptyCartMessage(product),
    updateProductCount: product => updateProductCountMessage(product.id, product.count)
    };
    
export default connect(mapStateToProps,mapDispatchToProps)(Checkout);