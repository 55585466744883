import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
// import Button from "./basic-components/button";

import moment from 'moment'

import Img from 'gatsby-image'

import BackgroundImage from 'gatsby-background-image'

import loadable from '@loadable/component'

import { FaFacebook, FaFacebookSquare } from "react-icons/fa"

import "../scss/site.scss";


const Button = loadable(() => import("./basic-components/button"))


const Header = ({ eventDate, pageData, headerTitle, description, backgroundUrl, primaryCTALabel, primaryCTAUrl, secondaryCTALabel, secondaryCTAUrl, headerType, shareOnSocial }) => {

  var headerStyle;
  // if(pageData.headerBgImage){
  //   headerStyle = {
  //     backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.9)), url(` + pageData.headerBgImage + `)`
  //   }
  // }


  function ShareOnSocial(sharePlatform, link) {
    return (window.open(sharePlatform + link, 'popupwindow', 'scrollbars=yes, width=800, height=400'))

  }

  useEffect(() => {
    if (pageData.headerBgImage && pageData.headerBgImage.fluid && pageData.headerBgImage.fluid.srcWebp) {
      const preloadLink = document.createElement("link");
      preloadLink.href = pageData.headerBgImage.fluid.srcWebp;
      preloadLink.rel = "preload";
      preloadLink.as = "image";
      document.head.appendChild(preloadLink);
    }
    // console.log("background", Background)
    // console.log("SecondaryImage", SecondaryImage) 

  }, [])


  switch (headerType) {
    case "contact":
      return (pageData.headerRightBgColor && pageData.headerLeftBgColor ? (
        <div className='color-divided-container'>

          <div className='left-bcg-color' style={{ background: `${pageData.headerLeftBgColor}` }}>
            <div className='align-with-logo'>
              <div className="left-header-text lateral-padding">
                <div className="header-container">
                  <div
                    className="uk-panel"
                  >


                    <h1 >{pageData.headerTitle} </h1>


                    {pageData.headerDescription ? <p className='header-paragraph'>{pageData.headerDescription} </p> : ''}
                    <div className="header-cta-container split-panel">
                      {pageData.headerCTAPrimaryUrl ?
                        <Link to={pageData.headerCTAPrimaryUrl} className="btn-primary accent">
                          {pageData.headerCTAPrimaryLabel}
                        </Link>
                        : ""
                      }


                      {pageData.headerCTASecondaryUrl ?
                        <Link to={pageData.headerCTASecondaryUrl} className="btn-secondary">{pageData.headerCTASecondaryLabel}</Link>
                        : ""
                      }

                    </div>

                  </div>

                </div>
              </div>
            </div>

          </div>
          <div className='right-bcg-color' style={{ background: `${pageData.headerRightBgColor}` }}>
            <div className='right-header-image'>
              <Img
                fluid={pageData.headerBgImage}
                style={{ height: "100%", width: "100%" }}
                loading='eager'
              />

            </div>
            <div className="right-header-text" style={{ background: `${pageData.headerLeftBgColor}` }}>
              <div className="header-container header-container-mobile">

                <div className='lateral-padding'>


                  <h1 >{pageData.headerTitle} </h1>

                  {pageData.headerDescription ? <p className='header-paragraph'>{pageData.headerDescription} </p> : ''}
                  <div className="header-cta-container">
                    {pageData.headerCTAPrimaryUrl ?
                      <Link to={pageData.headerCTAPrimaryUrl} className="btn-primary accent">{pageData.headerCTAPrimaryLabel}</Link>
                      : ""
                    }

                    {pageData.headerCTASecondaryUrl ?
                      <Link to={pageData.headerCTASecondaryUrl} className="btn-secondary">{pageData.headerCTASecondaryLabel}</Link>
                      : ""
                    }

                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      )

        : (<header className=" full-height-bg" style={headerStyle}>
          <BackgroundImage
            Tag="section"
            fluid={pageData.headerBgImage}
            backgroundColor={`#040e18`}
            role="img"
            aria-label={headerTitle}>
            <div className="">
              <div className=" full-height-header">
                <div className="uk-panel herotext-section">


                  <div className="uk-position-bottom-left lateral-padding">
                    <h1>{pageData.headerTitle} </h1>


                    {pageData.headerDescription ?
                      <p className="header-description">{pageData.headerDescription}</p> :
                      ""
                    }
                  </div>





                  <div className="uk-position-bottom-right">
                    {pageData.headerCTAPrimaryLabel ?
                      <Link to={`${pageData.headerCTAPrimaryUrl}`}><Button label={pageData.headerCTAPrimaryLabel}></Button></Link> :
                      ""
                    }
                  </div>

                </div>

              </div>
            </div>
          </BackgroundImage>
        </header>)
      )



    default:
      return (pageData.headerRightBgColor && pageData.headerLeftBgColor ? (
        <div className='color-divided-container'>
          <div className='left-bcg-color' style={{ background: `${pageData.headerLeftBgColor}` }}>
            <div className="left-header-text lateral-padding">
              <div className="header-container">
                <div
                  className="safari-header"
                >



                  <h1 >{pageData.headerTitle} </h1>



                  {eventDate ? <p className="header-paragraph">{moment(eventDate).format('dddd, Do MMMM YYYY, HH:mm')}</p> : ''}
                  {pageData.headerDescription ? <p className='header-paragraph'>{pageData.headerDescription} </p> : ''}

                  {shareOnSocial ? <div className="">
                    <p className='share-on-social '> Share on:  <a href="#0" onClick={() => ShareOnSocial("http://www.facebook.com/share.php?u=", window.location.href)} className='share-social_icon'><FaFacebook size="1.75rem" /></a></p>


                  </div> : ''
                  }

                  {/* {
                          pageData.headerCTAPrimaryUrl || pageData.headerCTASecondaryUrl ? <>
                            {pageData.headerCTAPrimaryUrl?
                              <Link to={pageData.headerCTAPrimaryUrl} className="btn-primary accent">{pageData.headerCTAPrimaryLabel}</Link>
                              :""
                            }

                            

                            {pageData.headerCTASecondaryUrl?
                              <Link   to={pageData.headerCTASecondaryUrl} className="btn-secondary">{pageData.headerCTASecondaryLabel} </Link>
                              :""
                            }
                            
                          </> :''
                         }  */}

                  {/**
                          small chnage to remove split panel if none of the links exists
                          */}

                  <div className="header-cta-container split-panel">
                    {pageData.headerCTAPrimaryUrl ?
                      <Link to={pageData.headerCTAPrimaryUrl} className="btn-primary accent">{pageData.headerCTAPrimaryLabel}</Link>
                      : ""
                    }



                    {pageData.headerCTASecondaryUrl ?
                      <Link to={pageData.headerCTASecondaryUrl} className="btn-secondary">{pageData.headerCTASecondaryLabel} </Link>
                      : ""
                    }

                  </div>

                </div>

              </div>
            </div>
          </div>
          <div className='right-bcg-color' style={{ background: `${pageData.headerRightBgColor}` }}>
            <div className='right-header-image'>
              <Img
                fluid={pageData.headerBgImage}
                style={{ height: "100%", width: "100%" }}
                loading='eager'
              />

            </div>
            <div className="right-header-text " >
              <div className="header-container ">
                <div
                  className="uk-panel safari-header lateral-padding"
                //  className=""
                >

                  <h1 >{pageData.headerTitle}</h1>

                  {eventDate ? <p className="='header-paragraph">{moment(eventDate).format('dddd, Do MMMM YYYY, HH:mm')}</p> : ''}
                  {pageData.headerDescription ? <p className='header-paragraph'>{pageData.headerDescription} </p> : ''}

                  {/* {shareOnSocial ?  <div className='share-on-social-container header-paragraph'>
                                <span className='share-on-social'> Share on: </span> 

                                <a href="#0" onClick={() => ShareOnSocial("http://www.facebook.com/share.php?u=", window.location.href)} className='share-social_icon'><FaFacebook size="1.75rem"/></a>
                              </div> : ''
                            } */}


                  {shareOnSocial ? <div className="">
                    <p className='share-on-social '> Share on:  <a href="#0" onClick={() => ShareOnSocial("http://www.facebook.com/share.php?u=", window.location.href)} className='share-social_icon'><FaFacebook size="1.75rem" /></a></p>


                  </div> : ''
                  }

                  <div className="header-cta-container">
                    {pageData.headerCTAPrimaryUrl ?
                      <Link to={pageData.headerCTAPrimaryUrl} className="btn-primary accent">{pageData.headerCTAPrimaryLabel}</Link>
                      : ""
                    }

                    {pageData.headerCTASecondaryUrl ?
                      <Link to={pageData.headerCTASecondaryUrl} className="btn-secondary">{pageData.headerCTASecondaryLabel}</Link>
                      : ""
                    }

                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      ) : (

        <header className=" full-height-bg" style={headerStyle}>

          <BackgroundImage
            Tag="section"
            fluid={pageData.headerBgImage}
            // backgroundColor={`#040e18`} 
            role="img"
            aria-label={headerTitle}>
            <div className="lateral-padding">
              <div className="full-height-header ">
                <div className="uk-panel ">


                  <h1> {pageData.headerTitle} </h1>

                  {eventDate ? <p className="header-paragraph">{moment(eventDate).format('dddd, Do MMMM YYYY, HH:mm')}</p> : ''}
                  {pageData.headerDescription ? <p className='header-paragraph'>{pageData.headerDescription} </p> : ''}

                  {shareOnSocial ? <div className="">
                    <p className='share-on-social header-paragraph'> Share on:  <a href="#0" onClick={() => ShareOnSocial("http://www.facebook.com/share.php?u=", window.location.href)} className='share-social_icon'><FaFacebook size="1.75rem" /></a></p>


                  </div> : ''
                  }
                  <div className="header-cta-container">


                    {pageData.headerCTAPrimaryUrl ?
                      <Link to={pageData.headerCTAPrimaryUrl} className="btn-primary accent">{pageData.headerCTAPrimaryLabel}</Link>
                      : ""
                    }




                    {pageData.headerCTASecondaryUrl ?
                      <Link to={pageData.headerCTASecondaryUrl} className="btn-secondary">{pageData.headerCTASecondaryLabel}</Link>
                      : ""
                    }

                  </div>

                </div>

              </div>
            </div>
          </BackgroundImage>


        </header>)
      )


  }

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
