import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import Carousel from "../components/carousel"
// import CategoryBar from "../components/category-bar"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import Tiles from "../components/tiles"

import loadable from '@loadable/component'

const Carousel = loadable(() => import("../components/carousel"))
const CategoryBar = loadable(() => import("../components/category-bar"))
const Tiles = loadable(() => import("../components/tiles"))






const Collections = () => {


  const data = useStaticQuery(graphql`
    {
      allJrmProducts 
      (sort: {fields: PriorityScore, order: DESC})
        {
        nodes {
          PriorityScore
          SDItemCategoryId
          PayOnline
          HidePrices
          Id
          Name
          AttractivePrice
          Description
          Slug
        }
      }

      allJrmCategories {
        nodes {
          Id
          Name
        }
      }

      allJrmFilteredCategories {
        nodes {
            Id
        }
      }

      strapiTopCollections {
        Title
        Description
        SEOTitle
        SEODescription
        Collections {
          id
          Title
          url
          Background {
            url
            localFile{
              childImageSharp{
                fluid(quality: 90, maxWidth: 1900) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)



  var FilteredCategories = []
  data.allJrmCategories.nodes.forEach((item, index) => {
    var exists = data.allJrmFilteredCategories.nodes.find(filteredItem => filteredItem.Id === item.Id)
    if (exists) {
      FilteredCategories.push({
        Id: item.Id,
        Name: item.Name
      })
    }
  })

  const listId = [5, 14, 17, 18, 21, 24]



  // let shuffledProducts = data.allJrmProducts.nodes.filter((item) => listId.includes(item.SDItemCategoryId));
  
  let shuffledProducts = data.allJrmProducts.nodes
    .map((value) => ({ value, sort: Math.random() }))
    .map(({ value }) => value)
    .filter(value => listId.includes(value.SDItemCategoryId))
    .sort((a, b) => a.sort - b.sort)
    .slice(0, 20)
    

  return (
    <div>
      <Layout headerShow={false}>
        <SEO title={data.strapiTopCollections.SEOTitle} description={data.strapiTopCollections.SEODescription} />

        {/* <CategoryBar data={FilteredCategories}></CategoryBar> */}

        {/* <ul>
        {data.allJrmProducts.nodes.map((item, i) => {

            return (

                <li key={i}>{item.Name} - {item.Id}</li>
            )
        })}
        </ul> */}
        <section class="subheading-text padding-top lateral-padding">
          <div class="text-container">
            <div class="react-markdown-element"></div>
            <div class="subheading"> <h1>{data.strapiTopCollections.Title}</h1><p>{data.strapiTopCollections.Description}</p></div>

            </div></section>
       

        <Tiles data={data.strapiTopCollections.Collections}></Tiles>
        {/* andrei - client requested to hide these */}
{/* 
        <Carousel title={"Collections"} data={shuffledProducts} url="/collections"></Carousel> */}


        {/* {products.map((item, i) => {
          const {Name, Description} = item
            return(  
                <div>
                    {Name, Description}
                </div>
            )
        })}
   */}


      </Layout>



    </div>
  )
}

export default Collections