import React from "react"
import { graphql, Link } from "gatsby"
import "../scss/branch.scss"
import 'uikit/dist/css/uikit.css'
import SEO from "../components/seo"
import Layout from "../components/layout"
import useSiteMetadata from "../utils/useSiteMetadata"
import { removeSlashFromSlug } from '../utils/additionalFunctions';
import loadable from '@loadable/component'


const SubheadingText = loadable(() => import("../components/basic-components/subheading-text"))
const Button = loadable(() => import("../components/basic-components/button"))
const Signup = loadable(() => import("../components/signup"))




export const query = graphql`
query branchesQuery($id: Int, $nickname: String) {
  strapiBranches(strapiId: {eq: $id}) {
    SEOTitle
    SEODescription
    Canonical
    MetaTags{
      MetaName
      MetaContent
    }
    Address
    BranchName
    ContactFormToken
    Email
    SubheadingText
    MapImage {
      localFile{
        publicURL
      }
      
    }
    BranchGMapsNickname
    
    Header{
      LeftBgColor
      RightBgColor
      Title
      Subtitle
      Background{
        localFile{
          publicURL
          childImageSharp{
            fluid(quality: 90, maxWidth: 1900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      CTA
      CTASecondary
      URL
      URLSecondary
    }
    PhoneNo
    strapiId
    OpeningHours
  }

  allStrapiBranches(filter: {strapiId: {ne: $id}}) {
    edges {
      node {
        BranchName
        strapiId
        Email
        PhoneNo
      }
    }
  }

  staticMap(nickname: {eq: $nickname}) {
    childFile {
      publicURL
    }
    mapUrl
  }
}
`



const Branches = ({data, pageContext}) => {

  var headerData={
    headerTitle: data.strapiBranches.Header?data.strapiBranches.Header.Title:data.strapiBranches.BranchName, 
    headerDescription: data.strapiBranches.Header?data.strapiBranches.Header.Subtitle:"", 
    headerCTAPrimaryLabel: data.strapiBranches.Header?.CTA, 
    headerCTAPrimaryUrl: data.strapiBranches.Header?.URL, 
    headerCTASecondaryLabel: data.strapiBranches.Header?.CTASecondary, 
    headerCTASecondaryUrl: data.strapiBranches.Header?.URLSecondary,
    headerBgImage: data.strapiBranches.Header?.Background?.localFile.childImageSharp.fluid||{src:process.env.GATSBY_DEFAULT_NA_IMAGE},
    headerLeftBgColor: data.strapiBranches.Header?data.strapiBranches.Header.LeftBgColor:"",
    headerRightBgColor: data.strapiBranches.Header?data.strapiBranches.Header.RightBgColor:""
  };

  


  const sendBranchMessage = event =>{

    var form = document.getElementById("branch-contact-form")
    // console.log(form.elements["branchmessage-enquiry-type"].value) //returns selected radio option
    // console.log(form)


    var cform = {
      FirstName: form.elements["branchmessage-first-name"].value,
      LastName: form.elements["branchmessage-last-name"].value,
      Email: form.elements["branchmessage-email"].value,
      PhoneNo: form.elements["branchmessage-phone-no"].value,
      // EnquiryType: form.elements["branchmessage-enquiry-type"].value,
      Message: form.elements["branchmessage-message"].value,
      AcceptOffers: form.elements["branchmessage-offers"].checked,
    }

  

  
    var submitBtn = document.getElementById("sendBranchMessage").firstChild;
    submitBtn.innerHTML = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="spinner" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg>';
    submitBtn.classList.add("form-submitting");
  
    var body = {
      Token: data.strapiBranches.ContactFormToken,
      FormData: JSON.stringify(cform)   
    };
  
    // console.log(body, "message sent!")
    let spamDomains = ["mailinator","aosod.com","guerrillamail","spam4.me","pokemail.net","sharklasers.com","tcwlx.com","yopmail.com","nfstripss.com","maildrop.cc"];
    let isSpam = 0
    spamDomains.forEach(function (domain, index) { 
      if(JSON.stringify(cform).includes(domain))
        {
          isSpam = 1;
        }
    });
    
      if(isSpam == 0)
      {
        fetch("https://api.phaeria.com/api/home/SendEmailNotification", {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(response => 
          response.text().then(function (responseText) {
            //console.log("response text: " + responseText);
            if(responseText.localeCompare('"Form sent successfully."') === 0){
              //success send message
              //console.log("success"+ responseText);
              submitBtn.innerHTML = "Message sent!";
              submitBtn.classList.remove("form-submitting");
            }else{
              //error sending message
              //console.log("something went wrong");
              submitBtn.innerHTML = "Oops... Try again!";
              submitBtn.classList.remove("form-submitting");
            }
              
      
          })
        )
      }
      else{
        submitBtn.innerHTML = "Message sent!";
        submitBtn.classList.remove("form-submitting");
      }
    // end of fetch
  
    // console.log("Message sent");
  
    event.preventDefault();
  }

 
  const handleGtagBranchPhone = (title) => {
    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

      window.gtag('event', 'branch-call', { 'event_category': 'ecommerce', 'event_label': `${title}` });

    }
  }

  const handleGtagBranchEmail = (title) => {
    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

      window.gtag('event', 'branch-email', { 'event_category': 'ecommerce', 'event_label': `${title}` });

    }
  }
  const { siteUrl: url } = useSiteMetadata();

    return(
    <div>
      <Layout pageData={headerData} headerShow={true} headerType="contact">
        <SEO title={data.strapiBranches.SEOTitle?data.strapiBranches.SEOTitle:data.strapiBranches.BranchName} 
         description={data.strapiBranches.SEODescription} 
         meta={data.strapiBranches.MetaTags}
         canonical={data.strapiBranches.Canonical ? data.strapiBranches.Canonical : `${url + '/branch/' + removeSlashFromSlug(data.strapiBranches.BranchName)}`}
        />


        {data.strapiBranches.SubheadingText?
        <SubheadingText text={data.strapiBranches.SubheadingText}/>:
        ""
        }
        
    
        <div className="branch-page-wrapper">
          <div className="branch-page lateral-padding">
  
            <section className="branch-details">
  
              <div className="branch-section-title">Our Details</div>
              {/* <hr /> */}
  

              <div className="details-section">
  
                <div className="uk-grid-collapse uk-child-width-expand@s" data-uk-grid>
                  <div>
                    <div className="details-text multiline-text" dangerouslySetInnerHTML={{ __html: data.strapiBranches.Address }}></div>
                    <div className="">
                      <div onClick={() => handleGtagBranchPhone(data.strapiBranches.BranchName)}>
                      
                      <a href={`tel:${data.strapiBranches.PhoneNo}`} className="phone-link">{data.strapiBranches.PhoneNo}</a>
                      </div>
                      <div className="branch-email phone-link" onClick={() => handleGtagBranchEmail(data.strapiBranches.BranchName)}><a href={"mailto:" + data.strapiBranches.Email}>{data.strapiBranches.Email}</a></div>
                    </div>

                    <div>
                      <div className="column-title">Opening Hours</div>
                      <div className="details-text" dangerouslySetInnerHTML={{ __html: data.strapiBranches.OpeningHours }}></div>
    
                    </div>
                  </div>

                  <div>
                    <div className="map-section">
    
                      {data.staticMap?
                      <a href={data.staticMap.mapUrl} target="_blank">
                        <div className="image-section uk-height-medium uk-cover-container uk-margin-none">
                          <img src={data.staticMap.childFile.publicURL||""} alt={data.strapiBranches.BranchName + " map"} data-uk-cover/>
                        </div>
                      </a>:<div className="image-section uk-cover-container uk-margin-none"></div>
                      }
                      
                    </div>
                  </div>

                </div>
              </div>
  
              
              <div className="enquiry-btn-wrap">
                  <a href="#enquiry-popup" aria-label="enquiry button">
                      <Button use="" label="Enquiry"></Button>
                  </a>
              </div>

            </section>
  
            <hr />

            <section className="other-branches">
              <div className="branch-section-title">Our other branches</div>
              


              <div uk-grid class="uk-grid uk-grid-column-small uk-grid-row-small uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l  uk-child-width-1-5@xl uk-text-center" >
                {data.allStrapiBranches.edges.map((item, index) => {
                  return(
                    <div key={index} className="other-branch-wrap ">
                      <div className="uk-card uk-card-default uk-card-body">
                      
                        <div className="branch-name-fs">{item.node.BranchName}</div>
                        <div><Link className="branch-name-fs" to={"/branch/" + item.node.BranchName.replace(/[\s+.]/g, '-').replace(/[*+~.,_^&#£%|()'"?!:@]/g, '').toLowerCase()}>Find out more</Link></div>
                      
                        <div onClick={() => handleGtagBranchPhone(item.node.BranchName)}>
                        <a href={`tel:${item.node.PhoneNo}`} className="phone-link">{item.node.PhoneNo}</a>
                        </div>
                        <div className="branch-email phone-link" onClick={() => handleGtagBranchEmail(item.node.BranchName)}><a href={"mailto:" + item.node.Email}>{item.node.Email}</a></div>										
                      </div>

                    </div>
                  )
                })}
              </div>

            </section>


            
  
  
          </div>


          <Signup 
            title = "Sign Up to Our Newsletter"
            description = "Get updates on all the lastest information, or stay informed about our courses"
            buttonLabel = "Sign Up"
          />




        </div>
        
  
      </Layout>
  
  
      
    </div>
  )
    }
  
  export default Branches