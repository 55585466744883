import React from "react"
import {graphql, StaticQuery, Link, useStaticQuery} from "gatsby";

import {StaticImage} from 'gatsby-plugin-image'
import { removeSlashFromSlug } from '../utils/additionalFunctions';


const Footer = ({}) => {


  const data = useStaticQuery(graphql`
  query{
    strapiFooter {
      FacebookURL
      FooterLogo
      FooterStructure
      FooterURL
      InstagramURL
    }
    allStrapiLegals {
      edges {
        node {
          Title
          Content
          slug
          strapiId
        }
      }
    }

    allJrmCategories {
      nodes {
          Name
          Id
      }
    }

    allJrmFilteredCategories {
      nodes {
          Id
      }
    }
  }
`)

  var FilteredCategories = []
  data.allJrmCategories.nodes.forEach((item, index) => {
    var exists = data.allJrmFilteredCategories.nodes.find(filteredItem => filteredItem.Id === item.Id)
    if(exists){
      FilteredCategories.push({
        Id: item.Id,
        Name: item.Name
      })
    }
  })

  var gridList = [];
  let myIndex = 0;
  var currentGrid = [];
  data.strapiFooter.FooterStructure.forEach((item, index) => {
    switch (item.strapi_component) {
      case "menu.submenu-column":
        {
          currentGrid.push({Id:index,section:item});
          break;
        }
      case "basic-components.menu-grid-divider":  
      {
        gridList.push({id: myIndex, grid:currentGrid});
        myIndex++;
        currentGrid = [];
        break;
      }
    }
  })
  gridList.push({id: myIndex, grid:currentGrid});
  myIndex++;
  
 

    return(

    <div className="">

        <section className="footer-section">

          <div className="footer-social">
            <a href={data.strapiFooter.FacebookURL} target="_blank" ><span uk-icon="icon: facebook; ratio: 1.3"></span></a>
            {/* <a href="#twitter" target="_blank"><FaTwitter size="1.5em"></FaTwitter></a> */}
            <a href="https://www.instagram.com/mindfulmemorials/" target="_blank"><span uk-icon="icon: instagram; ratio: 1.3"></span></a>
          </div>
          <div className="footer-wrapper">
              <div data-uk-grid>
                {gridList.map((item,index) => {
                  return(
                    <>
                    <div className={`uk-width-1-${myIndex}@m uk-width-1-2@s uk-text-left`}>
                      {item.grid.map((myGrid, secIndex) => {

                        return (
                          <>
                          <div className="col-title">{myGrid.section.SectionTitle}</div>
                          <ul>
                           {myGrid.section.SubmenuList.map((myLink, thirdIndex) => {
                            return(
                              <li>{
                                myLink.URL.startsWith("tel:")?
                                  <a href={myLink.URL}>{myLink.Label}</a>:
                                  <Link to={myLink.URL}>{myLink.Label}</Link>
                                  }
                                
                              </li>
                            )
                          })} 
                          </ul>
                          </>
                        )
                      })

                      }
                    
                    </div>
                    </>

                  )
                })
                }


              </div>
          </div>
          <div className="footer-affiliates">
            <a href={data.strapiFooter.FooterURL} target="_blank" rel="noreferrer">
            <StaticImage src="../assets/img/logo_namm.png" alt='The National Association of Memorial Masons logo' width={150} height={168}/>
            {/* <img src={nammImage} width="150" alt="The National Association of Memorial Masons logo"></img> */}</a>
          </div>
        </section>

        <footer>
          <div className=" footer-wrapper">
            {/* <div className="footer-left">© Mindful Memorials {new Date().getFullYear()}</div>
            <div className="footer-right">Powered by Phaeria</div> */}
            Copyright © Mindful Memorials {new Date().getFullYear()}
            {data.allStrapiLegals.edges.map((item, index) => {             
              return(<span key={index}> | <Link to={'/' + removeSlashFromSlug(item.node.slug)}>{item.node.Title}</Link></span>)
            })}
          </div>

          
        </footer>
    </div>
      )

    
}

export default Footer